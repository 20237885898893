import styled from "styled-components";

export const LoadIngStyle = styled.div`
height: ${({ h }) => h + 'px'};
text-align: center;
background: #fff;
border-radius: 0.25rem;
margin: 12.5rem 0;
touch-action: none;
`;
export const TitleStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin:auto;
  margin-top: ${({ t }) => t + 'px'};
  img{
    width: 100%;
    height: 100%;
  }
`;

export const TopBoxStyle = styled.div`
height: ${({ h }) => h + 'px'};
width: 100%;
border: 1px solid #fff;
`
export const FormBoxStyle = styled.div`
height: ${({ h }) => h + 'px'};
width: 100%;
border: 1px solid #fff;
`

export const FormBodyStyle = styled.div`
width: 70%;
margin:auto;
margin-top: 1.5625rem;
`

export const BootomBoxStyle = styled.div`
height: ${({ h }) => h + 'px'};
width: 100%;
`

export const BootomStyle = styled.div`
width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin:auto;
  img{
    width: 100%;
    height: 100%;
  }
`

export const LoginBody = styled.div`
  height: ${({ h }) => h + 'px'};
  *touch-action: none;
`

export const SpanStyle = styled.span`
    font-size: .625rem;
    color: #ff5c23;
`

/** 下载页相关样式 */
export const DownloadSpanStyle = styled.span`
    font-size: .9375rem;
    color: #ff5c23;
`

export const DownloadBox = styled.div`
  height: ${({ h }) => h + 'px'};
  width: ${({ w }) => w + 'px'};
  touch-action: none;
  position: absolute;
`

export const DownloadImage = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin:auto;
  margin-top: ${({ t }) => t + 'px'};
  img{
    width: 100%;
    height: 100%;
  }
`

export const DownloadStoreImage = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin:auto;
  margin-top: ${({ t }) => t + 'px'};
  img{
    width: 100%;
    height: 100%;
  }
`

export const DownloadButtonStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin:auto;
  text-align: center;
  margin-top: ${({ t }) => t + 'px'};
  background-color: #ff5c23;
  border-radius: .5rem;
  span{
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    line-height: 42px;
    color: #fff;
  }
`

export const MaskBoxStyle = styled.div`
  height: ${({ h }) => h + 'px'};
  width: ${({ w }) => w + 'px'};
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  position: relative;
  touch-action: none;
`

export const MaskImage = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin-left: ${({ m_l }) => m_l + 'px'};
  margin-top: ${({ t }) => t + 'px'};
  border: 1px solid rgba(0, 0, 0, 0);
  img{
    width: 100%;
    height: 100%;
  }
`

export const MaskImageStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  border: 1px solid rgba(0, 0, 0, 0);
  position: relative;
`

export const MaskCloseButtonStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin:auto;
  margin-top: ${({ t }) => t + 'px'};
  border: 1px solid #fff;
  border-radius: .9375rem;
  text-align: center;
  span{
    color: #fff;
    line-height: 1.875rem;
  }
`

/** 分享页相关样式 */

export const ShareBodyStyle = styled.div`
  width: 100%;
  height: 75.4063rem;
`

export const ShareTopStyle = styled.div`
  width: 100%;
  height: 14.4688rem;
`

export const ShareButtomStyle = styled.div`
  width: 100%;
  height: 60.9375rem;
`

export const ShareButtomImageStyle = styled.div`
  width: 100%;
  height: 60.9375rem;
  position: absolute;
`

export const ShareButtonStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin-top: ${({ t }) => t + 'px'};
  margin-left: ${({ l }) => l + 'px'};
  border-radius: .625rem;
  position: relative;
`