import React, { useState, useEffect } from "react";
import axios from "axios";
import { DownloadBox, DownloadImage, DownloadButtonStyle, MaskBoxStyle, MaskImage, MaskImageStyle, MaskCloseButtonStyle } from "./styles";

const Download = (props) => {
    const [width] = useState(window.innerWidth)
    const [height] = useState(window.innerHeight)
    const [is_show, setIsShow] = useState(false)
    const [android, setAndroid] = useState('https://img.shahaizhi.com/%E4%B8%80%E6%96%B9%E6%B0%B4%E5%9C%9F%E5%95%86%E5%9F%8E_V1.0.117_10117_jiagu_sign.apk')
    const [ios, setIos] = useState('https://apps.apple.com/cn/app/id1603430098')

    useEffect(() => {
        document.title = '一方水土商城APP下载';
        init_download()
    }, [props]);

    const init_download = () => {
        Request({
            url: "version/getDownloadUrl",
            method: "GET",
        }).then((res) => {
            setAndroid(res.user_android);
            setIos(res.user_ios);
        });
    };

    const open_mask = () => {
        let ckeck_is_wx = isWeiXin()
        if (ckeck_is_wx) {
            setIsShow(true)
        } else {
            let platform = getPlatform();
            if (platform === 'android') {
                //安卓下载链接
                window.location.href = android
            } else {
                //ios下载链接
                window.location.href = ios
            }
        }
    }
    const close_mask = () => {
        setIsShow(false)
    }

    const isWeiXin = () => {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true; // 是微信端
        } else {
            return false;
        }
    }

    const getPlatform = () => {
        var ua = window.navigator.userAgent.toLowerCase();
        let platform = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1;
        if (platform) {
            return 'android'
        } else {
            return 'ios'
        }
    }

    return (<>
        <DownloadBox h={height} w={width}>
            <><DownloadImage w={254.5 / 375 * width} h={188.5 / 667 * height} t={160 / 667 * height}>
                <img src="image/3.png" />
            </DownloadImage>
                <DownloadButtonStyle w={221.5 / 375 * width} h={42 / 667 * height} t={59 / 667 * height} onClick={open_mask}>
                    <span>点击免费下载</span>
                </DownloadButtonStyle>
            </>
        </DownloadBox>
        {is_show ? <>
            <MaskBoxStyle h={height} w={width}>
                <MaskImageStyle w={width} h={211.5 / 667 * height} >
                    <MaskImage w={248.5 / 375 * width} h={170.5 / 667 * height} t={41 / 667 * height} m_l={98 / 375 * width} >
                        <img src="image/5.png" />
                    </MaskImage>
                    <MaskCloseButtonStyle w={105 / 375 * width} h={30 / 667 * height} t={56 / 667 * height} onClick={close_mask}>
                        <span>我知道了</span>
                    </MaskCloseButtonStyle>
                </MaskImageStyle>
            </MaskBoxStyle>
        </> : <></>}
    </>
    );
};

const Request = (option) => {
    return new Promise((resolve, reject) => {
        axios({
            url: option.url,
            method: option.method,
            baseURL: "https://api.user.shahaizi.shop/api/user/",
            params: option.data || null,
            timeout: 10000,
            withCredentials: true,
        }).then((response) => {
            let data = response.data;
            if (data.code === 200) {
                resolve(data.result);
            } else {
                reject(data.result);
            }
        });
    });
};

export default Download;
