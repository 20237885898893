import React, { useState, useEffect } from "react";
import { ShareBodyStyle, ShareTopStyle, ShareButtomStyle, ShareButtomImageStyle, ShareButtonStyle } from "./styles";

const Share = (props) => {
    const [width] = useState(window.innerWidth)
    const [height] = useState(window.innerHeight)

    useEffect(() => {
        document.title = '一键分享';
    }, [props]);

    const onClick = () => {
        window.location.href = "intent://share";
    }
    return (<>
        <ShareBodyStyle>
            <ShareTopStyle>
                <img src="image/spic_02.jpg" width={width} height={231.5} />
            </ShareTopStyle>
            <ShareButtomStyle>
                <ShareButtomImageStyle>
                    <img src="image/share.png" width={width} height={975} />
                </ShareButtomImageStyle>
                <ShareButtomImageStyle>
                    <ShareButtonStyle w={274/375*width} h={65} t={864} l={53/375*width} onClick={onClick}/>
                </ShareButtomImageStyle>
            </ShareButtomStyle>
        </ShareBodyStyle>
    </>
    );
};

export default Share;
