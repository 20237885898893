import React, { useEffect, useState } from "react";
import { Spin, Form, Input, Button, Checkbox, Select, Row, Col, message, Drawer } from "antd";
import axios from "axios";
import Iframe from 'react-iframe';
import { LoadIngStyle, LoginBody, TitleStyle, TopBoxStyle, FormBoxStyle, FormBodyStyle, BootomBoxStyle, BootomStyle, SpanStyle, DownloadSpanStyle } from './styles'
const Option = Select.Option

const Login = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [width] = useState(window.innerWidth)
    const [height] = useState(window.innerHeight)
    const [count, setCount] = useState(60)
    const [invite_code, setInviteCode] = useState(props.match.params.invite_code)
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState(0)
    const [service] = useState(
        <div class="ab_container">
            <p>
                《隐私政策》
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                我们的隐私政策已于2021年12月22日更新。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                请花一些时间熟悉我们的隐私政策，如果您有任何问题，请联系我们。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>关于我们</strong>
            </p>
            <p>
                尊敬的用户，感谢您使用一方水土APP为您提供的应用服务。一方水土（隶属于上海樱蓝网络科技有限公司，以下或称“我们”）非常注重保护用户（“您”）的个人信息及隐私，并全力遵守国家法律法规的要求对您的个人信息进行保护。鉴于您在使用我们的服务时，我们可能会收集和使用您的相关信息，为向您阐明用户信息收集、使用、共享、管理及保护的规则，特制定本《隐私政策》（以下或称“本政策”）。
            </p>
            <p>
                <strong>在使用一方水土各项产品或服务前，请您务必仔细阅读并透彻理解本政策（特别是加粗或下划线的内容），在确认充分理解并同意后使用相关产品或服务。一旦您开始使用一方水土各项产品或服务，即表示您已充分理解并同意本政策。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>目录</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                为了使您能更清晰而详尽地了解我们的个人信息收集使用惯例，所以请参考以下目录阅读对应章节。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>本政策将帮助您了解以下内容：</strong>
            </p>
            <p>
                一、隐私政策的适用范围
            </p>
            <p>
                二、我们收集哪些信息以及如何使用信息
            </p>
            <p>
                三、我们如何做信息使用
            </p>
            <p>
                四、我们如何共享、转让、公开披露您的个人信息
            </p>
            <p>
                五、信息保存及跨境传输
            </p>
            <p>
                &nbsp;六、我们如何使用&nbsp;Cookie&nbsp;和同类技术
            </p>
            <p>
                七、个人敏感信息提示
            </p>
            <p>
                八、信息安全与保护
            </p>
            <p>
                九、信息安全事件处置
            </p>
            <p>
                十、未成年人信息保护
            </p>
            <p>
                十一、用户个人信息管理
            </p>
            <p>
                十二、从中国大陆地区以外访问我们的APP
            </p>
            <p>
                十三、本政策如何更新
            </p>
            <p>
                十四、如何联系我们
            </p>
            <p>
                十五、相关法律说明
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>一、隐私政策的适用范围</strong>
            </p>
            <p>
                除某些特定服务外，我们所有的服务均适用《隐私政策》。特定服务将适用特定的隐私政策或协议。该特定服务的隐私政策或协议构成《隐私政策》的一部分。如任何特定服务的隐私政策或协议与《隐私政策》有不一致之处，则适用特定服务的隐私政策或协议。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                请您注意，《隐私政策》不适用于以下情况：通过我们的服务而接入的第三方服务（包括任何第三方网站、APP）收集的信息；通过在我们服务中进行广告服务的其他公司和机构所收集的信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>二、我们收集哪些信息以及如何使用信息</strong>
            </p>
            <p>
                为了更好地为您提供服务，我们将遵循“合理、相关、必要”原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。考虑到服务的重要性，经用户同意，我们将会收集您在我们APP输入的或者以其他方式提供给我们的所有信息。
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                根据您选择的服务，我们可能收集以下信息中的一种或多种：
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                <strong>1.</strong><strong>您主动提供给我们的个人信息</strong>
            </p>
            <p>
                我们会根据您选择的服务，收集您在使用相应服务时需要提供的个人信息，例如：
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                1）在您注册为一方水土会员时，需提供<strong>手机号、短信验证码</strong>并<strong>设置密码</strong>用以创建一方水土账号，如果您拒绝提供，可能无法成为注册用户并正常下单，但是可以浏览。
            </p>
            <p>
                2）在您使用商城购物时，需要填写收货地址，需要您<strong>提供收件人姓名、手机号、详细的收货地址</strong>，以便于您能准确安全的收到购买的商品。
            </p>
            <p>
                3）在您从一方水土账户提现时，需要先添加银行卡以便于可以正常提现，添加银行卡时需<strong>提供真实姓名、银行卡号、所属银行</strong>等信息。
            </p>
            <p>
                4）当您联系我们的客服以获得我们的客户服务时，您还需要提供您的<strong>用户信息（收货人姓名、手机号）、订单信息、订单号码</strong>来验证身份。
            </p>
            <p>
                5）在您使用APP的过程中，我们在个人信息模块将提供个人信息编辑功能，完成信息填充我们将收集您的<strong>头像、昵称、性别、生日、地区、个性签名、手机号</strong>，若您不提供上述信息，依然可以正常使用本产品。
            </p>
            <p>
                6）<strong>为您推荐商品</strong>：为了节省您搜索商品的时间，我们会根据商品的上新时间、促销优惠、好评率等维度为您推荐商品，目的是增加浏览商品机会，提升您的网购效率。<strong>但此功能不会通过结合您的购买记录、浏览记录、加购物车等方式进行相关推荐，请放心使用。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>2.</strong><strong>我们将在您使用服务过程中，自动收集您的个人信息：</strong>
            </p>
            <p>
                1）日志信息，指您使用我们的服务时，系统可能通过cookies或其他方式自动采集的信息，包括：
            </p>
            <p>
                2）设备信息或软件信息，例如您的IP地址和移动设备所用的版本和设备识别码（IDFA/IMEI/OAID）、设备应用安装列表、以及通过网页浏览器、移动设备用于接入我们服务的配置信息。
            </p>
            <p>
                3）在使用我们服务时搜索或浏览的信息，例如您使用APP搜索词语、访问页面，以及您在使用我们服务时浏览或提供的其他信息。
            </p>
            <p>
                4）您通过我们的服务分享的内容所包含的信息，例如拍摄或上传照片或评论，以及日期、时间等。
            </p>
            <p>
                5）为了提供更好的产品和/或服务并改善用户体验，<strong>我们会在前端或后台收集设备属性信息（例如您的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码</strong><strong>IMEI</strong><strong>、网络设备硬件地址MAC</strong><strong>信息、广告标识符IDFA</strong><strong>等）</strong>，设备连接信息（浏览器的类型、电信运营商、使用的语言）以及设备状态信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>三、</strong><strong>这些个人信息将会被如何使用</strong>
            </p>
            <p>
                我们收集个人信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。这包括：
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>1.</strong><strong>帮助您成为我们的用户</strong>
            </p>
            <p>
                为注册成为用户并使用我们的服务，您需要自主提供<strong>手机号码、密码和</strong><strong>/</strong><strong>或验证码</strong>用于创建账户。同时，您也可以自主设置您的<strong>昵称、头像、性别、生日</strong>，来完善您的账户信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                您授权以微信账号或QQ账号等第三方账号登录APP后，我们将根据您的授权以间接方式获取相关账号的<strong>用户标识、登录名、昵称、头像、姓名以及登录状态信息</strong>。并将微信或其他第三方账号与APP账户进行绑定。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>2.</strong><strong>为您提供商品或服务信息展示功能</strong>
            </p>
            <p>
                在您使用我们服务过程中，为契合您的页面展示和推送服务，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>设备信息</strong>：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息,包括设备型号、操作系统版本、设备设置、唯一设备标识符（如：IMEI/OAID/IDFA/MAC地址/SIM卡信息/IMSI/Android_ID/BSSID/ICCID）、设备广告标识符、设备环境、设备名称、硬盘、硬件序列号、内存等软硬件特征信息、手机样式/手机型号，网络设备制造商、系统版本、任务列表、WI-FI信息，设备所在位置相关信息（包括您授权的GPS位置、经度、纬度以及WLAN接入点、蓝牙和基站传感器信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>服务日志信息</strong>：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、预约、添加至购物车、交易、售后、分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息</strong>。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>3.</strong><strong>为您提供加购、分享、到货提醒功能</strong>
            </p>
            <p>
                在您浏览我们的过程中，您可以选择对感兴趣的商品及/或服务进行添加至购物车、分享的功能，也可以对缺货商品开启到货提醒功能。在您使用上述功能的过程中，我们会自主收集包括您的添加购物车的记录、分享商品记录、到货提醒记录在内的服务日志信息用于实现上述功能。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>4.</strong><strong>为您提供下单及订单管理功能</strong>
            </p>
            <p>
                当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需提供<strong>收货人姓名、收货地址、收货人联系电话</strong>，同时该订单中会载明您所购买的<strong>商品及</strong><strong>/</strong><strong>或服务信息、具体订单号、订单创建时间、商品价格、您应支付的金额</strong>，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                如果您通过一方水土APP为其他人订购商品及/或服务，您需要提供该实际订购人的前述个人信息并确保信息来源合法、征得当事人授权同意。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                为便于您了解查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。&nbsp;
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                您可额外填写/选择包括收货时间在内的更多附加信息以确保商品或服务的准确送达。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>5.</strong><strong>帮助您完成支付的功能</strong>
            </p>
            <p>
                为完成订单支付，您需要自主选择支付方式或支付渠道，并<strong>授权同意我们将订单支付相关信息及其他反洗钱法律要求的必要信息与您所选择的第三方支付机构共享。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您授权同意我们可自您所选择的第三方支付机构或您选择的其他金融机构处收集与支付进度相关信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>6.</strong><strong>向您完成商品或服务交付的功能</strong>
            </p>
            <p>
                根据您与商品及/或服务提供主体的约定，相关配送服务主体（包括商家或第三方配送服务提供商）将为您完成订单的交付，您知悉并同意我们有权将您的<strong>订单信息</strong>提供给商品/服务供应商、物流服务商，以保证您购买的商品及/或服务能够顺利、安全、准确送达。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>7.</strong><strong>客服及争议处理</strong>
            </p>
            <p>
                当您与我们联系或提出售中、售后争议纠纷处理申请时，为了保障您的账户及系统安全，<strong>我们需要您提供必要的个人信息以核验您的身份。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/</strong><strong>通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>8.</strong><strong>为您提供商品评论等信息公开发布功能</strong>
            </p>
            <p>
                您可通过我们为您提供的商品评论及其他信息发布功能公开发布信息，包括作为会员可发布图文/视频内容及发表评价。我们将收集您发布的信息，并展示您的昵称、头像和发布内容。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>请注意，我们强烈不建议您公开发布的信息中包含您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。同时我们也有权基于信息安全原因，对相关信息予以屏蔽和删除。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>9.</strong><strong>为您提供安全保障</strong>
            </p>
            <p>
                为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或叮咚买菜相关协议规则的情况，我们会使用或整合您的<strong>账户信息、订单信息、设备信息、日志信息、地理位置信息、软件列表、软件使用环境</strong>以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、欺诈检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施，确保网络及信息安全。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>10.</strong><strong>为您提供其他附加服务</strong>
            </p>
            <p>
                <strong>为向您提供更便捷、更优质、个性化的产品及/</strong><strong>或服务，努力提升您的体验，我们在向您提供的以下服务中可能会获取您相应的权限，并在获取权限时提示告知。如果您不开启这些权限，可能无法获得这些服务给您带来的用户体验。这些服务包括：</strong>
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                <strong>摄像头（相机）</strong>。基于摄像头、相机提供以下功能：
            </p>
            <p>
                当您更换头像时、评价商品需要拍摄时，提交各类图片、意见反馈图片、客服聊天图片等需要拍摄时，我们会申请摄像头、相机权限，以完成拍摄、拍照的功能；你可以拒绝授权，拒绝后将无法使用相关功能，但并不影响您正常使用app的其它服务与功能。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>相册、图片</strong>。基于相册、图片提供以下功能：
            </p>
            <p>
                当您更换头像、评价商品需要上传图片时，提交各类图片、意见反馈图片、客服聊天图片等需要上传图片时，我们会申请访问相册、图片的权限来完成上传图片等功能；你可以拒绝授权，拒绝后将无法使用相关功能，但并不影响您正常使用app的其它服务与功能。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>读取外置存储器</strong>。基于读取外置存储器提供以下功能：
            </p>
            <p>
                访问您设备上的照片、媒体内容和文件，目的用于查找上传相册或拍摄后的照片视频、查找及发送手机中文件，使用前我们会申请权限来完成读取功能，你可以拒绝授权，拒绝后将无法使用相关功能，但并不影响您正常使用app的其它服务与功能。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>写入外置存储器</strong>。基于写入外置存储器提供以下功能：
            </p>
            <p>
                目的用于存储拍摄后的照片视频、存储软件更新后的安装包、聊天信息发送和接收的文件。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>麦克风</strong>。基于麦克风提供以下功能：
            </p>
            <p>
                的聊天时，语音发送消息功能：您可以使用该功能在APP内完成发送语音聊天信息；使用前我们会申请权限来使用麦克风，你可以拒绝授权，拒绝后将无法使用相关功能，但并不影响您正常使用app的其它服务与功能。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>拨打电话</strong>。基于拨打电话提供以下功能：
            </p>
            <p>
                当联系客服、联系紧急救援电话、联系商务时，需使用拨打电话功能，需要获取手机打电话通话的相关权限，我们会在使用前申请获取相关权限，你可以拒绝授权，拒绝后可能出现无法拨打电话等问题。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>电话权限的附加功能</strong>：您可以使用该功能授权我们获取IMEI、IMSI等设备识别符信息，以用于保障您的账号和交易安全。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>文件上传</strong>。基于文件上传提供的功能：
            </p>
            <p>
                用于聊天时查找及发送手机中文件，我们会在使用前申请获取相关权限，你可以拒绝授权，拒绝后可能出现无法发送文件等问题。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                您理解并同意，上述服务可能需要您在您的设备中开启您的权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>11.</strong><strong>您在享受我们提供的各项服务的同时，授权并同意接受我们向您的电子邮件、手机、通信地址等发送商业信息，包括不限于最新的产品信息、促销信息等。若您选择不接受我们提供的各类信息服务，您可以按照我们提供的相应设置拒绝该类信息服务。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>12.</strong><strong>您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意：</strong>
            </p>
            <p>
                ·与国家安全、国防安全有关的；
            </p>
            <p>
                ·与公共安全、公共卫生、重大公共利益有关的
            </p>
            <p>
                ·与犯罪侦查、起诉、审判和判决执行等有关的；
            </p>
            <p>
                ·维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p>
                ·所收集的个人信息是个人信息主体自行向社会公众公开的；
            </p>
            <p>
                ·从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </p>
            <p>
                ·根据您的要求签订合同所必需的；
            </p>
            <p>
                ·用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
            </p>
            <p>
                ·为合法的新闻报道所必需的；
            </p>
            <p>
                ·学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
            <p>
                ·法律法规规定的其他情形。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>13.SDK</strong><strong>的使用</strong>
            </p>
            <p>
                为保障一方水土APP的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，可在此协议最后的附录表查看。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>四、我们如何共享、转让、公开披露您的个人信息</strong>
            </p>
            <p>
                <strong>1.</strong><strong>共享</strong>
            </p>
            <p>
                <strong>除以下情况外，我们不会将您的个人信息提供给一方水土以外的任何个人、公司或组织共享：</strong>
            </p>
            <p>
                ·使用App时第三方SDK获取的信息（详见第三方SDK目录），例如：我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力；
            </p>
            <p>
                ·依据本政策或事先已获得您明确的同意或授权；
            </p>
            <p>
                ·根据适用的法律、法规、法律程序的要求、行政或司法的强制性要求所必须时；
            </p>
            <p>
                ·在法律、法规允许的范围内，为维护一方水土及关联方或合作伙伴、您或其他一方水土用户或社会公共利益、财产或安全免遭损害时；
            </p>
            <p>
                ·为实现我们的服务的核心功能或提供您需要的服务；
            </p>
            <p>
                ·应您的需求为您处理您与他人的纠纷或争议；
            </p>
            <p>
                ·根据与您签署的相关协议或其他法律文件的约定；
            </p>
            <p>
                ·基于学术研究而使用；
            </p>
            <p>
                ·基于符合法律、法规规定的社会公共利益而使用。
            </p>
            <p>
                ·您充分理解并同意，我们在防止欺诈等违法活动、减少信用风险等情况下，可能与其他公司或组织交换用户个人信息，但并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它任何方式披露的个人信息。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>2.</strong><strong>信息转让</strong>
            </p>
            <p>
                <strong>除以下情况外，我们不会将您的个人信息转让给任何个人、公司或组织：</strong>
            </p>
            <p>
                ·事先获得您明确的同意或授权；
            </p>
            <p>
                ·根据适用的法律、法规、法律程序的要求、行政或司法的强制性要求所必须时；
            </p>
            <p>
                ·根据与您签署的相关协议或其他法律文件的约定；
            </p>
            <p>
                ·涉及合作、联营、合并、收购、资产转让或其他类似交易时。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>3.</strong><strong>公开披露</strong>
            </p>
            <p>
                <strong>我们非常重视保护您的个人信息，未经您同意，我们不会将其披露给无关的第三方，更不会将其公之于众，但因下列原因而披露的除外：</strong>
            </p>
            <p>
                ·由于App接收推送消息，以及聊天会话信息时可能产生的其他情况。例如：推送订单相关信息，接收客服的会话聊天信息时第三方sdk可能会使App频繁自启动以及关联启动，以保证消息的送达。
            </p>
            <p>
                ·获得您明确同意或基于您的主动选择；
            </p>
            <p>
                ·根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息；
            </p>
            <p>
                ·其他依据法律规定或双方约定可以披露的情形。
            </p>
            <p>
                ·您理解并同意，根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>五、信息保存及跨境传输</strong>
            </p>
            <p>
                1.您的个人信息我们将保存至您账号注销之日后的三个月，除非需要延长保留期或受到法律或者有权机关的允许或协助有权机关进行查询。
            </p>
            <p>
                2.如我们停止运营一方水土产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
            </p>
            <p>
                3.我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，部分场景需要将您的个人信息传输至境外：
            </p>
            <p>
                ·获得您的明确授权；
            </p>
            <p>
                ·当营销场景涉及到海外服务提供商时；
            </p>
            <p>
                ·当您通过互联网进行跨境交易等个人主动行为；
            </p>
            <p>
                ·法律法规的明确要求。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>六、&nbsp;</strong><strong>我们如何使用&nbsp;Cookie&nbsp;</strong><strong>和同类技术</strong>
            </p>
            <p>
                为了让您获得更轻松便捷的访问体验，您访问我们app或服务时，我们可能会在您的设备终端或系统上存储名为&nbsp;Cookie的小型数据文件用来识别您的身份，这样可以帮您省去重复输入注册信息的步骤，帮您优化对广告的选择与互动及其帮助判断您的账户安全状态。<strong>您有权接受或拒绝</strong><strong>Cookie</strong><strong>，如果浏览器自动接收Cookie</strong><strong>，您可以根据自己的需要修改浏览器的设置以拒绝Cookie</strong><strong>。请注意，如果您选择拒绝Cookie</strong><strong>，那么您可能无法完全体验我们提供的服务。</strong>
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                一方水土未来可能会设立为您提供产品及服务的网站，为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名Cookie、Flash Cookie或浏览器或关联应用程序提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于&nbsp;Cookie，网站能够存储您的偏好或购物车内的商品等数据。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。有关详情，请参见&nbsp;AboutCookies.org。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>日志文件</strong>：&nbsp;和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议&nbsp;(IP)&nbsp;地址、浏览器类型、互联网服务供应商&nbsp;(ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据。我们不会把自动收集的日志数据与我们收集的其他信息进行关联。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>七、个人敏感信息提示</strong>
            </p>
            <p>
                个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：&nbsp;个人财产类信息（包括银行帐号、交易和消费记录、虚拟财产信息）、个人身份信息（包括身份证、驾驶证、照片）、网络身份标识信息（包括账户名、邮箱地址及与前述有关的密码），以及其他信息（包括个人电话号码等信息）。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                这些个人敏感信息将受到严格保护，我们隐私政策在此提醒您，您在使用我们为您提供的产品及服务中所上传或发布的内容和信息可能会披露您的个人敏感信息，而这些信息并不一定是提供服务所必需收集的信息。<strong>因此，您需要在使用我们为您提供的产品或服务前谨慎考虑。您同意这些个人敏感信息将按照《隐私政策》阐明的目的和方式来进行处理</strong>。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>八、信息安全与保护</strong>
            </p>
            <p>
                1.我们对可能接触到您信息的员工也采取了严格管理，可监控他们的操作情况，对于数据访问、内外部传输使用、脱敏、解密等重要操作建立了审批机制，并与上述员工签署保密协议等。与此同时，我们还定期对员工进行信息安全培训，要求员工在日常工作中形成良好操作习惯，提升数据保护意识。
            </p>
            <p>
                2.尽管有前述的安全措施，但同时也请您理解在网络上不存在“完善的安全措施”。我们会按现有的技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄露、损毁或丢失。
            </p>
            <p>
                3.您的帐户均有安全保护功能，请妥善保管您的帐号及密码信息，切勿将密码告知他人，如果您发现自己的个人信息泄露，特别是您的帐号和密码发生泄露，请您立即与我们的客服联系，以便我们采取相应的措施。
            </p>
            <p>
                4.请您及时保存或备份您的文字、图片等其他信息，您需理解并接受，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
            </p>
            <p>
                5.“在使用一方水土服务进行网上交易时，请您妥善保护自己的个人信息（包括但不限于出行人姓名、联络方式或联系地址），仅在必要的情形下向他人提供。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性，。为防止他人未经授权使用您的密码或使用您的计算机、移动设备或SIM卡，如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络客服，以便我们核实后根据您的申请采取相应措施。”
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>九、信息安全事件处置</strong>
            </p>
            <p>
                在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>十、未成年人信息保护</strong>
            </p>
            <p>
                1.&nbsp;我么非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应事先取得法定监护人的同意。我们根据《中华人民共和国未成年人保护法》等国家相关法律法规的要求对未成年人的个人信息及隐私进行保护。
            </p>
            <p>
                2.我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们仅在法律法规允许、监护人同意或保护未成年人所必要的情况下使用、共享、转让或披露此类信息。
            </p>
            <p>
                3.作为监护人，您可以在APP端登录后，通过“我的”对未成人的相关信息进行查询、更正和删除操作。如果有事实证明未成年人并未取得监护人同意的情况下注册和使用了我们的服务，监护人可以联系客服，我们会在确认后尽快删除相关未成年人的个人信息。
            </p>
            <p>
                4.对于不满14周岁的儿童个人信息，我们还会遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，按照《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露。当您作为监护人为被监护的儿童选择使用我们相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用评价功能时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。如您对儿童个人信息相关事宜有任何意见、建议或投诉，请联系我们。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>十一、用户个人信息管理</strong>
            </p>
            <p>
                1.您可以随时登录“我的”-个人中心、常用信息等在APP端登录后，通过“我的”-&gt;“常用信息”来，查询、更正及删除自己的账户信息，修改个人资料、隐私设置、安全设置，修改收货地址等使用我们服务时您提供的个人信息，如有问题，可以联系客服。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>十二、从中国大陆地区以外访问我们的APP</strong>
            </p>
            <p>
                如果您从中国大陆以外地区访问我们产品，请您注意，您的信息可能被传送至、存储于中国大陆，并且在中国大陆进行处理。中国大陆的数据保护法和其他法律可能与您所在国家/地区的法律不同，但请相信我们将采取措施保护您的信息。选择使用我们的服务的同时，您了解并同意您的信息可能被传送至我们的网站，并如本隐私政策所诉的与我们共享信息的第三方。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>十三、本政策如何更新</strong>
            </p>
            <p>
                我们会根据业务和技术的变更，根据适用法律，依循良好做法，定期审核隐私政策，并可能更新本隐私政策，请您理解，我们将标注本隐私政策最近更新的日期，更新将于发布时生效。进入APP时，未经您明确同意，我们不会削减您按照本隐私权政策所应享有的权利。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私权政策的具体变更内容）。请您经常回访本隐私政策，以阅读最新版本。在我们向您收集更多的个人信息或我们因为新的目的使用或披露您的个人信息时，我们会根据适用法律要求，再次征得您的同意。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>十四、如何联系我们</strong>
            </p>
            <p>
                您可以通过以下方式与我们联系，我们将在15天内回复您的请求：
            </p>
            <p>
                1.如对本政策内容、儿童个人信息保护有任何疑问、意见或建议，您可通过一方水土客服与我们联系
            </p>
            <p>
                2.您还可以通过访问APP，或通过以下电子邮箱地址yinglanwangluo@163.com或者致电021-55698841与我们取得联系。
            </p>
            <p>
                &nbsp;
            </p>
            <p>
                <strong>十五、法律</strong>
            </p>
            <p>
                隐私政策要与该国的国家法律解析一致，包括法律条款中有争议抵触的内容。用户和我们一致同意服从法院的管辖。若有任何条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响，因解释本隐私政策以及用户通过我们预订任何产品而导致的争议，以及任何跟用户个人信息或隐私保护相关的纠纷，将同意接受上海市人民法院的管辖。
            </p>
            <p>
                <br />
            </p>

            <p>
                <span style="">附：《傻孩子第三方</span>SDK<span style="">目录》</span>
            </p>
            <p style="text-align:center">
                <strong><span style="font-size:19px;">傻孩子第三方SDK目录</span></strong>
            </p>
            <table cellspacing="0" cellpadding="0" width="100%">
                <tbody>
                    <tr style=";height:23px" class="firstRow">
                        <td width="85" style="border: 1px solid windowtext; padding: 0 7px;" height="23">
                            <p style="text-align:center">
                                <strong>SDK</strong><strong><span style="">服务</span></strong>
                            </p>
                        </td>
                        <td width="84" style="border-top: 1px solid windowtext; border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-image: initial; border-left: none; padding: 0 7px;" height="23">
                            <p style="text-align:center">
                                <strong><span style="">具体功能</span></strong>
                            </p>
                        </td>
                        <td width="80" style="border-top: 1px solid windowtext; border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-image: initial; border-left: none; padding: 0 7px;" height="23">
                            <p style="text-align:center">
                                <strong><span style=";">客户端</span></strong>
                            </p>
                        </td>
                        <td width="208" style="border-top: 1px solid windowtext; border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-image: initial; border-left: none; padding: 0 7px;" height="23">
                            <p style="text-align:left">
                                <strong><span style=";">使用目的/数据收集范围与方式</span></strong>
                            </p>
                        </td>
                        <td width="114" style="border-top: 1px solid windowtext; border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-image: initial; border-left: none; padding: 0 7px;" height="23">
                            <p style="text-align:center">
                                <strong><span style=";">隐私权政策</span></strong>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;;color:black">支付宝SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">支付</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;;color:black">使用目的</span></strong><span style="font-size:16px;color:black">：帮助用户在应用内使用支付宝</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://render.alipay.com/p/f/fd-jm7jym6r/alipay/multi-agreement.html"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式</span></strong>
                                <span style="font-size:16px;color:black">：网络状态、</span><span style="font-size:16px;color:black">Wifi</span><span style="font-size:16px;color:black">状态、手机状态、读写存储空间、改变</span>
                                <span style="font-size:16px;color:black">Z</span>
                                <span style="font-size:16px;color:black">轴排列任务</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">微信支付SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">支付</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">使用目的</span></strong><span style="font-size:16px;color:black">：微信登录</span><span style="font-size:16px;color:black">/</span>
                                <span style="font-size:16px;color:black">分享</span><span style="font-size:16px;color:black">/</span><span style="font-size:16px;color:black">微信支付功能</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_business_pay"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式</span></strong>
                                <span style="font-size:16px;color:black">：网络状态、</span><span style="font-size:16px;color:black">Wifi</span><span style="font-size:16px;color:black">状态、手机状态、读写存储空间、改变</span>
                                <span style="font-size:16px;color:black">Z</span>
                                <span style="font-size:16px;color:black">轴排列任务</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">银联支付SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">支付</span>
                            </p>
                        </td>
                        <td width="80" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" rowspan="2" colspan="1">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">使用目的</span></strong><span style="font-size:16px;color:black">：用于向您提供支付服务</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://wallet.95516.com/s/wl/WebAPP/helpAgree/page/agreement/regPrivacy.html?cntryCode=86"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:19px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="19">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式</span></strong>
                                <span style="font-size:16px;color:black">：网络状态、</span><span style="font-size:16px;color:black">Wifi</span><span style="font-size:16px;color:black">状态、手机状态、读写存储空间、改变</span>
                                <span style="font-size:16px;color:black">Z</span>
                                <span style="font-size:16px;color:black">轴排列任务</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">bugly &nbsp; SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">系统安全（崩溃捕捉）</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用于监控APP崩溃，以便与开发者定位问题，不涉及用户信息</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://bugly.qq.com/v2/contract"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:22px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="22">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式</span></strong><strong><span style="font-size:16px;">：</span></strong>
                                <span style="font-size:16px;font-family:   宋体">设备信息、</span><span style="font-size:16px;color:black">手机状态、网络状态、读取日志</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">友盟SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">统计、</span><span style="font-size:16px;">分享</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用于统计信息以及分享</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://bugly.qq.com/v2/contract"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:23px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="23">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围与方式：</span></strong><span style="font-size:16px;">设备信息、</span><span style="font-size:16px;color:black">网络状态、</span><span style="font-size:   16px;color:black">Wifi</span>
                                <span style="font-size:16px;color:black">状态、手机状态、读写存储空间</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:27px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="27">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">微信分享SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="27">
                            <p style="text-align:center">
                                <span style="font-size:16px;">分享</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="27">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="27">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用于分享内容到微信平台</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="27">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://privacy.qq.com/"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:24px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式：</span></strong>
                                <span style="font-size:16px;color:black">WIFI</span><span style="font-size:16px;color:black">状态、手机状态、网络状态</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:21px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="21">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">QQ</span><span style="font-size:16px;color:black">分享SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="21">
                            <p style="text-align:center">
                                <span style="font-size:16px;">分享</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="21">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="21">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用于分享内容到QQ平台</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="21">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://privacy.qq.com/"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:13px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="13">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式：</span></strong>
                                <span style="font-size:16px;color:black">网络状态</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:31px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="31">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">微博分享SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="31">
                            <p style="text-align:center">
                                <span style="font-size:16px;">分享</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="31">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="31">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用于分享内容到微博平台</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="31">
                            <p style="text-align:center">
                                <span style="text-decoration:underline;"><span style="font-size:16px;color:#333333"><a href="https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE"><span style="color:#333333">点击查看第三方隐私政策</span></a>
                                </span>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:24px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式：</span></strong>
                                <span style="font-size:16px;color:black">剪贴板、位置信息、相册、</span><span style="font-size:16px;color:black">IDFA</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black">融云消息SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">聊天消息</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left;vertical-align:middle">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用于跟客服、司导等发送消息，即时通信</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black"><a href="https://www.rongcloud.cn/privacy"><span style="color:black">点击查看第三方隐私政策</span></a>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:42px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="42">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式：</span></strong>
                                <span style="font-size:16px;color:black">设备信息、</span><span style="font-size:16px;color:black">WIFI</span><span style="font-size:16px;color:black">状态、手机状态、网络状态</span>
                                <span style="font-size:16px;font-family:   宋体;color:black">、</span>
                                <span style="font-size:16px;color:black">相机、麦克风、蓝牙、</span><span style="font-size:16px;color:black">用户聊天信息、</span><span style="font-size:16px;color:black">读写存储空间</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:33px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="33">
                            <p style="text-align:center">
                                <span style="font-size:16px;">极光推送SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="33">
                            <p style="text-align:center">
                                <span style="font-size:16px;">消息推送</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="33">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="33">
                            <p style="text-align:left;vertical-align:middle">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">通知用户订单进度、物流消息、优惠消息、系统通知</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="33">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black"><a href="https://www.jiguang.cn/license/privacy"><span style="color:black">点击查看第三方隐私政策</span></a>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:24px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式：</span></strong>
                                <span style="font-size:16px;">设备信息、地理位置、网络信息</span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:24px">
                        <td width="85" rowspan="2" style="border-right: 1px solid windowtext; border-bottom: 1px solid windowtext; border-left: 1px solid windowtext; border-image: initial; border-top: none; padding: 0 7px;" height="24">
                            <p style="text-align:center">
                                <span style="font-size:16px;">七牛云SDK</span>
                            </p>
                        </td>
                        <td width="84" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:center">
                                <span style="font-size:16px;">图片存储</span>
                            </p>
                        </td>
                        <td width="80" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:center">
                                <span style="font-size:16px;">Android/iOS</span>
                            </p>
                        </td>
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;">使用目的：</span></strong><span style="font-size:16px;">用与储存图片信息</span>
                            </p>
                        </td>
                        <td width="114" rowspan="2" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:center">
                                <span style="font-size:16px;color:black"><a href="https://www.qiniu.com/privacy-right"><span style="color:   black">点击查看第三方隐私政策</span></a>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style=";height:24px">
                        <td width="208" style="border-top: none; border-left: none; border-bottom: 1px solid windowtext; border-right: 1px solid windowtext; padding: 0 7px;" height="24">
                            <p style="text-align:left">
                                <strong><span style="font-size:16px;color:black">收集数据范围</span></strong><strong><span style="font-size:16px;color:black">/</span></strong><strong><span style="font-size:16px;color:black">方式：</span></strong>
                                <span style="font-size:16px;">图片信息、存储图片</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

    useEffect(() => {
        const {
            match: {
                params: { invite_code },
            },
        } = props;
        document.title = '用户注册';
        setInviteCode(invite_code)
    }, [props]);

    const onFinish = (values) => {
        if (values.remember === false) {
            message.warning('请勾选协议')
            return
        }
        Request({
            url: "member/htmlRegister",
            method: "POST",
            data: {
                phone: values.username,
                password: values.password,
                country_code: values.country_code,
                code: values.code,
                invite_code: invite_code
            },
        }).then((res) => {
            if (res.code === 200) {
                message.success('注册成功')
                download()
            } else {
                message.error(res.msg)
            }
        });
    };

    const send_code = () => {
        let username = form.getFieldValue('username');
        if (username) {
            let second = count;
            Request({
                url: "member/send-register-code",
                method: "POST",
                data: {
                    mobile: username,
                    country_code: form.getFieldValue('country_code')
                },
            }).then((res) => {
                console.log(res)
                if (res.code === 200) {
                    const timer = setInterval(() => {
                        if (second === 0) {
                            clearInterval(timer);
                            setCount(60)
                        } else {
                            setCount(second--)
                        }
                    }, 1000)
                    message.success(res.msg)
                } else {
                    message.error(res.msg)
                }
            });
        } else {
            message.error('手机号不得为空')
        }
    }

    const prefixSelector = (
        <Form.Item name="country_code" noStyle>
            <Select style={{ width: 70 }}>
                <Option value={86}>+86</Option>
                <Option value={87}>+87</Option>
            </Select>
        </Form.Item>
    );

    const download = () => {
        window.location.href = '#/download/app'
    }

    const onClose = () => {
        setVisible(false)
    }

    const show_service = (value) => {
        setVisible(true)
        setType(value)
    }

    return (
        loading ? (
            <LoadIngStyle h={height}>
                <Spin />
            </LoadIngStyle>
        ) : (
            <LoginBody h={height} w={width}>
                <TopBoxStyle h={189 / 667 * height}><TitleStyle w={190 / 375 * width} h={124 / 667 * height} t={65 / 667 * height} >
                    <img src="image/1.png" />
                </TitleStyle>
                </TopBoxStyle>
                <FormBoxStyle h={437.5 / 667 * height}>
                    <FormBodyStyle>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true, country_code: 86, invite_code: invite_code }}
                            onFinish={onFinish}
                            form={form}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: '请输入手机号' }]}
                            >
                                <Input addonBefore={prefixSelector} placeholder="请输入手机号" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: '请输入密码' }]}
                            >
                                <Input
                                    type="password"
                                    placeholder="请设置登录密码"
                                />
                            </Form.Item>
                            <Form.Item name="invite_code">
                                <Input
                                    placeholder="填写邀请码"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="code"
                                            noStyle
                                            rules={[{ required: true, message: '请填写验证码' }]}
                                        >
                                            <Input placeholder="请填写验证码" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'center', lineHeight: 3 }}>
                                        {count === 60 ? <Button type="link" onClick={send_code}>获取验证码</Button> : <span style={{ fontSize: 12 }}>{count}s后重新发送</span>}
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox><SpanStyle>我已详细阅读并同意</SpanStyle></Checkbox>
                                </Form.Item>
                                <div className="login-form-forgot">
                                    <a onClick={() => show_service(1)}><SpanStyle>《服务协议》</SpanStyle></a>
                                    <a onClick={() => show_service(2)}><SpanStyle>《隐私政策》</SpanStyle></a>
                                </div>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    注册
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <center><DownloadSpanStyle onClick={download}>已注册？立即下载App</DownloadSpanStyle></center>
                            </Form.Item>
                        </Form>
                    </FormBodyStyle>
                </FormBoxStyle>
                <BootomBoxStyle h={80.5 / 667 * height}>
                    <BootomStyle w={270 / 375 * width} h={42.5 / 667 * height} >
                        <img src="image/2.png" />
                    </BootomStyle>
                </BootomBoxStyle>

                <Drawer
                    title={type === 1 ? "服务协议" : "隐私政策"}
                    placement='bottom'
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                >
                    <Iframe
                        overflow="hidden"
                        height={'100%'}
                        frameBorder={0}
                        width={345 / 375 * width}
                        position="absolute"
                        url={type === 1 ? "https://api.user.shahaizi.shop/service_agreement.html" : "https://api.user.shahaizi.shop/privacy_policy.html"}
                    />
                </Drawer>
            </LoginBody>
        )
    );
};

const Request = (option) => {
    return new Promise((resolve, reject) => {
        axios({
            url: option.url,
            method: option.method,
            baseURL: "https://api.user.shahaizi.shop/api/user/",
            params: option.data || null,
            timeout: 10000,
            withCredentials: true,
        }).then((response) => {
            let data = response.data;
            resolve(data);
        });
    });
};

export default Login;
