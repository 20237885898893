import React, { Component } from 'react'
import './App.less';
import { Route, Switch } from 'react-router-dom'
import GoodsDetail from './detail';
import Login from './login';
import Download from './download';
import Share from './share';
import Politely from './politely';
import StoreDownload from './store_download';

export default class App extends Component {
  render() {
    return <Switch>
      <Route path='/:goodid' exact component={GoodsDetail} />
      <Route path='/login/:invite_code' exact component={Login} />
      <Route path='/download/app' exact component={Download} />
      <Route path='/download/store_app' exact component={StoreDownload} />
      <Route path='/html/share' exact component={Share} />
      <Route path='/html/politely/:invite_code' exact component={Politely} />
    </Switch>
  }
}