import { message } from "antd";
import React, { useState, useEffect } from "react";
import { BodyStyle, BackgroungStyle, TitleStyle, GoodsStyle, GoodsImage, GoodsInfo, GoodsNameAndPrice, GoodsName, GoodsPrice, CodeStyle, ButtonStyle, ButtonMenu } from "./politely_styles";
import axios from "axios";
import Clipboard from 'clipboard';
import { useCallback } from "react";

const Politely = (props) => {
    const [width] = useState(window.innerWidth)
    const [height] = useState(window.innerHeight)
    const [invite_code, setInviteCode] = useState()
    const [data, setData] = useState({})

    useEffect(() => {
        document.title = '分享有礼';
        const {
            match: {
                params: { invite_code },
            },
        } = props;
        init_data(invite_code)
    }, [props]);

    const init_data = (invite_code) => {
        Request({
            url: "share/getSharePolitelyInfo",
            method: "GET",
            data: {
                invite_code: invite_code,
            },
        }).then((res) => {
            setData(res)
            setInviteCode(invite_code)
        });
    };

    const copy = useCallback(() => {
        const copy = new Clipboard('.invite_code')
        message.success('复制成功')
    }, [])
    const jump_to_login = () => window.location.href = 'https://api.user.shahaizi.shop/#/login/' + invite_code

    return (<>
        <BodyStyle w={width} h={height}>
            <BackgroungStyle w={width} h={223.05 / 667 * height} />
            <TitleStyle w={230 / 375 * width} h={66 / 667 * height} t={28.5 / 667 * height} b={10.5 / 667 * height} f={18 / 375 * width}>
                <span>您的好友赠送您一份赠品</span><br />
                <span>凭【邀请码】注册APP</span><br />
                <span>登录后可以领取赠品</span>
            </TitleStyle>
            <GoodsStyle w={350 / 375 * width} h={319.45 / 667 * height} p={10 / 375 * width}>
                <GoodsInfo h={299.45 / 667 * height}>
                    <><GoodsImage w={90 / 375 * width} h={90 / 667 * height} >
                        <img src={data.main_pic} />
                    </GoodsImage>
                        <GoodsNameAndPrice w={192 / 375 * width} h={90 / 667 * height}>
                            <GoodsName w={192 / 375 * width} h={33.5 / 667 * height} f={15 / 375 * width}>
                                <span>{data.name}</span>
                            </GoodsName>
                            <GoodsPrice w={192 / 375 * width} h={13.5 / 667 * height} f={14 / 375 * width}>
                                <span>{data.giveaway_price === 0 ? '0元领取' : data.giveaway_price + '元'}</span>
                            </GoodsPrice>
                        </GoodsNameAndPrice>
                    </>
                    <CodeStyle w={300 / 375 * width} h={200 / 667 * height} m={100 / 667 * height}>
                        <p style={{ paddingTop: 18.5, paddingBottom: 18.5, fontSize: 18, color: '#FF0000' }}>邀请码</p>
                        <p style={{ paddingBottom: 18.5, fontSize: 30, color: '#FF0000' }} >{invite_code}</p>
                        <p style={{ paddingBottom: 28, fontSize: 15, color: '#017FFF' }} data-clipboard-text={invite_code} className='invite_code' onClick={copy}>复制</p>
                        <p style={{ fontSize: 14, letterSpacing: -1 }}>请正确填写邀请码，否则无法收到好友的赠品</p>
                    </CodeStyle>
                </GoodsInfo>
                <ButtonStyle w={332 / 375 * width} h={50 / 667 * height} m={18 / 667 * height} onClick={jump_to_login}>
                    <p>注册赢好礼</p>
                </ButtonStyle>
                <p style={{ textAlign: 'center', marginTop: 19, color: '#909090' }}>限时领取，数量有限，先到先得</p>
            </GoodsStyle>
        </BodyStyle>
    </>
    );
};

const Request = (option) => {
    return new Promise((resolve, reject) => {
        axios({
            url: option.url,
            method: option.method,
            baseURL: "https://api.user.shahaizi.shop/api/user/",
            params: option.data || null,
            timeout: 10000,
            withCredentials: true,
        }).then((response) => {
            let data = response.data;
            if (data.code === 200) {
                resolve(data.result);
            } else {
                reject(data.result);
            }
        });
    });
};

export default Politely;