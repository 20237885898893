import React, { useEffect, useState } from "react";
import { Avatar, Card, Carousel, Col, Comment, Divider, Row, Spin } from "antd";
import styled from "styled-components";
import axios from "axios";
import ReactPlayer from "react-player";
import Player from 'griffith'

ReactPlayer.removeCustomPlayers();
const Meta = Card.Meta;

let width = window.screen.width

const BodyStyle = styled.div`
  background-color: #f8f8ff;
`;
const LoadIngStyle = styled.div`
  height: 100%;
  text-align: center;
  background: #fff;
  border-radius: 0.25rem;
  margin: 12.5rem 0;
`;
const GoodsDetail = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [recommend_goods, setRecommendGoods] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const {
      match: {
        params: { goodid },
      },
    } = props;
    init_product(goodid);
  }, [props]);


  const init_product = (goodid) => {
    setLoading(true);
    Request({
      url: "goods/shop-goods-detail",
      method: "GET",
      data: {
        goods_id: goodid,
      },
    }).then((res) => {
      document.title = res.name;
      setData(res);
      init_recommend(res.catalog_id);
      init_comment(goodid);
      setLoading(false);
    });
  };

  const init_recommend = (category_id) => {
    Request({
      url: "goods/searchHtmlGoods",
      method: "GET",
      data: {
        cat_id: category_id,
      },
    }).then((res) => {
      setRecommendGoods(res);
    });
  };

  const init_comment = (goods_id) => {
    Request({
      url: "goods_review/get-goods-review-page",
      method: "GET",
      data: {
        pageno: 1,
        pagesize: 3,
        only_image: 0,
        goods_id: goods_id,
      },
    }).then((res) => {
      setComments(res.rows);
    });
  };

  return (
    <BodyStyle>
      {loading ? (
        <LoadIngStyle>
          <Spin />
        </LoadIngStyle>
      ) : (
        <>
          <Banner data={data} />
          <Title data={data} />
          <Share />
          <Review comments={comments} />
          <Detail data={data} />
          {/* <Recommend recommend_goods={recommend_goods} /> */}
        </>
      )}
    </BodyStyle>
  );
};

const BannerStyle = styled.div`
  height: width;
`;
const BannerImageStyle = styled.img`
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  background: url(${({ key }) => key});
  background-repeat: no-repeat;
  background-size:cover;
`;
const BannerVideoStyle = styled.div`
  width: ${({ width }) => width};
  height: ${({ width }) => width};
`;
const Banner = ({ data }) => {
  const duration = 182;
  const [banner, setBanner] = useState([]);
  const [video, setVideo] = useState("");
  const [prop, setProp] = useState({
    id: "zhihu2018",
    title: "视屏",
    cover: '',
    duration: 182,
    sources: {
      HD: {
        bitrate: 900.49,
        size: 20633151,
        duration,
        format: "mp4",
        width: width,
        height: width,
        play_url: video
      }
    },
    src: ''
  })

  useEffect(() => {
    init_date(data);
  }, [data]);

  const init_date = (data) => {
    let banner_images = data.banner_images;
    if (banner_images) {
      let banner = banner_images.split(",");
      setBanner(banner);
    }
    let video = data.video;
    if (video) {
      prop.sources.HD.play_url = video
      prop.src = video
      prop.cover = video + '?vframe/jpg/offset/0'
      setVideo(video);
      setProp(prop)
    }
  };

  return (
    <BannerStyle>
      <Carousel>
        {video ? (
          <div style={{ height: width }} key={0}>
            <BannerVideoStyle width={width}>
              <Player {...prop} />
            </BannerVideoStyle>
          </div>
        ) : null}
        {banner.map((item, index) => {
          return (
            <div style={{ height: width, width: width }} key={index}>
              <BannerImageStyle src={item} key={item} width={width} />
            </div>
          );
        })}
      </Carousel>
    </BannerStyle>
  );
};
const GoodsNameStyle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.3125rem 0.625rem;
`;
const PriceNameStyle = styled.div`
  color: red;
  font-size: 1.25rem;
  font-weight: 900;
  height: 1.5625rem;
  padding: 0 0.625rem;
`;
const SpecificationContentStyle = styled.div`
  height: 5.3125rem;
`;

const SpecificationStyle = styled.div`
  padding: 0.3125rem 0.9375rem;
  height: 1.375rem;
`;
const LabelStyle = styled.span`
  font-size: 0.8125rem;
  font-weight: 600;
`;
const DevlieryStyle = styled.span`
  color: gray;
  font-size: 0.75rem;
`;
const InstructionStyle = styled.span`
  color: #eead0e;
  font-size: 0.75rem;
`;
const Title = ({ data }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [place_of_delivery, setPlaceOfDevlivery] = useState("");

  useEffect(() => {
    init_date(data);
  }, [data]);

  const init_date = (data) => {
    setName(data.name);
    setPrice(data.price);
    setPlaceOfDevlivery(data.place_of_delivery);
  };

  return (
    <Card>
      <GoodsNameStyle>{name}</GoodsNameStyle>
      <PriceNameStyle>{price}</PriceNameStyle>
      <SpecificationContentStyle>
        <Divider />
        <SpecificationStyle>
          <Row>
            <Col span={4}>
              <LabelStyle>发货</LabelStyle>
            </Col>
            <Col span={20}>
              <DevlieryStyle>{place_of_delivery}</DevlieryStyle>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={4}>
              <LabelStyle>保障</LabelStyle>
            </Col>
            <Col span={20}>
              <InstructionStyle>
                严选好货
                <Divider type="vertical" />
                优质商品
                <Divider type="vertical" />
                售后保证
              </InstructionStyle>
            </Col>
          </Row>
          <Divider />
        </SpecificationStyle>
      </SpecificationContentStyle>
    </Card>
  );
};
const ShareImageStyle = styled.div`
  height: 5.625rem;
  img {
    width: 100%;
    height: 5.625rem;
  }
`;
const jump_to_share = () => {
  window.location.href = "intent://goodshare";
};
const Share = () => {
  return (
    <ShareImageStyle onClick={jump_to_share}>
      <img
        src="https://api.user.shahaizi.shop/static/images/goods_dt_03.png"
        alt=""
      />
    </ShareImageStyle>
  );
};

const ReviewLabelStyle = styled.span`
  font-size: 1.0625rem;
  font-weight: 600;
`;
const ReviewValueStyle = styled.span`
  float: right;
  margin-right: 0.3125rem;
  margin-top: 0.1875rem;
  color: #f4a460;
  font-size: 0.75rem;
`;
const Review = ({ comments }) => {
  const jump_to_review = () => {
    window.location.href = "intent://comment";
  };
  return (
    <>
      {comments.length === 0 ? (
        <></>
      ) : (
        <Card style={{ padding: 10, borderRadius: 5 }}>
          <Row>
            <Col span={10}>
              <ReviewLabelStyle>商品评价</ReviewLabelStyle>
            </Col>
            <Col span={14}>
              <ReviewValueStyle onClick={jump_to_review}>
                展开全部&nbsp;&nbsp;{">>"}
              </ReviewValueStyle>
            </Col>
          </Row>
          {comments.map((item) => {
            return (
              <Comment
                author={item.nickname}
                datetime={<FormateDate time={item.create_time} />}
                avatar={<Avatar src={item.face} />}
                content={<p>{item.content}</p>}
              />
            );
          })}
        </Card>
      )}
    </>
  );
};
const DetailImageStyle = styled.img`
  width: 100%;
  background-image: url(${({ key }) => {
    return key;
  }});
`;
const HtmlStyle = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

const Detail = ({ data }) => {
  const [images, setImages] = useState([]);
  const [goods_introduction, setGoodsIntroduction] = useState("");

  useEffect(() => {
    init_date(data);
  }, [data]);

  const init_date = (data) => {
    let goods_introduction_image = data.goods_introduction_image;
    if (goods_introduction_image) {
      let picture = goods_introduction_image.split(",");
      setImages(picture);
    } else {
      let goods_introduction = data.goods_introduction;
      if (goods_introduction) {
        setGoodsIntroduction(goods_introduction);
      }
    }
  };

  return (
    <>
      <Divider>图文详情</Divider>
      {images.map((item, index) => (
        <DetailImageStyle src={item} key={item} />
      ))}
      <HtmlStyle dangerouslySetInnerHTML={{ __html: goods_introduction }} />
    </>
  );
};

const RecommendImageStyle = styled.img`
  width: 100%;
  height: 4.375rem;
  background: url(${({ key }) => {
    return key;
  }});
`;

const MetaTitleStyle = styled.span`
  font-size: 0.75rem;
`;

const RecommendPriceStyle = styled.span`
  color: red;
  font-size: 0.9375rem;
  font-weight: 500;
`;

const Recommend = ({ recommend_goods }) => {
  const jump_to_goods = (key) => {
    window.location.href = "intent://gooddetail/" + key;
  };
  return (
    <Card>
      <Divider>推荐商品</Divider>
      <Row gutter={4}>
        {recommend_goods.map((item) => {
          let data = item;
          return (
            <Col span={12} style={{ padding: 10 }} key={data.id}>
              <Card
                cover={
                  <RecommendImageStyle
                    src={data.original}
                    key={data.original}
                    onClick={() => jump_to_goods(data.id)}
                  />
                }
              >
                <Meta
                  title={<MetaTitleStyle>{data.name}</MetaTitleStyle>}
                  description={
                    <RecommendPriceStyle>
                      {data.price ? "￥" + data.price : null}
                    </RecommendPriceStyle>
                  }
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};
const Request = (option) => {
  return new Promise((resolve, reject) => {
    axios({
      url: option.url,
      method: option.method,
      baseURL: "https://api.user.shahaizi.shop/api/user/",
      params: option.data || null,
      timeout: 10000,
      withCredentials: true,
    }).then((response) => {
      let data = response.data;
      if (data.code === 200) {
        resolve(data.result);
      } else {
        reject(data.result);
      }
    });
  });
};

const FormateDate = ({ time }) => {
  let date = new Date(time * 1000);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate < 10 ? "0" + date.getDate() : date.getDate();
  return Y + M + D;
};

export default GoodsDetail;
