
import styled from "styled-components";

export const BodyStyle = styled.div`
  height: ${({ h }) => h + 'px'};
  width: ${({ w }) => w + 'px'};
  overflow:hidden ;
`

export const BackgroungStyle = styled.div`
  width: 100%;
  height: ${({ h }) => h + 'px'};
  background-image:url('image/politely.png');
  background-size: cover ;
  position:absolute ;
  z-index: -1;
`

export const TitleStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  z-index: 1;
  margin: auto;
  margin-top: ${({ t }) => t + 'px'};
  margin-bottom: ${({ b }) => b + 'px'};
  text-align: center;
  line-height: 1.2715;
  span{
    font-size: ${({ f }) => f + 'px'};
    margin: 0;
    color: white ;
  }
`

export const GoodsStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  margin: auto;
  padding: ${({ p }) => p + 'px'};
`

export const GoodsInfo = styled.div`
  width: 100%;
  height: ${({ h }) => h + 'px'};
  background-color: white;
  border-radius: .625rem;
  padding: .625rem;
  box-shadow: #F0F0F0 .125rem .0313rem 1.25rem .125rem ;
`

export const GoodsImage = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  background-color: white;
  float:left ;
  img{
    width: 100%;
    height: 100%;
  }
`

export const GoodsNameAndPrice = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  float:left ;
  margin-left: .625rem;
`

export const GoodsName = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  float:left ;
  margin-top: .6875rem;
  line-height: 1.2715;
  span{
    font-size: ${({ f }) => f + 'px'};
    margin: 0;
  }
`

export const GoodsPrice = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  float:left ;
  margin-top: .9375rem;
  line-height: 1.1515;
  span{
    font-size: ${({ f }) => f + 'px'};
    margin: 0;
    color: #FF5C23 ;
  }
`

export const CodeStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  line-height: 1.1515;
  text-align: center;
  margin-top: ${({ m }) => m + 'px'};
  p{
    margin: auto;
  }
`

export const ButtonStyle = styled.div`
  width: ${({ w }) => w + 'px'};
  height: ${({ h }) => h + 'px'};
  line-height: 1.1515;
  margin: auto;
  margin-top: ${({ m }) => m + 'px'};
  background-image: linear-gradient(to right, #ff3d23 ,#ff6d3a);
  border-radius: ${({ h }) => h / 2 + 'px'};
  text-align: center;
  vertical-align: middle;
  p{
    font-size: 1.125rem;
    color: white;
    line-height: 3;
  }
`
